// Things related to Data Streaming page
const DataStreaming = {};

DataStreaming.totalNrOfFilesBeingSent = 0;
DataStreaming.nrOfOngoingUploadTasks = 0;
DataStreaming.isUploading = false;

DataStreaming.updateUploadingMessages = function(error)
{
	updateUploadingMessages(error, DataStreaming.totalNrOfFilesBeingSent, DataStreaming.nrOfOngoingUploadTasks);
};

// Takes in a file object from an html file input field
// and returns a file stream from that file
DataStreaming.getFileStream = async (file) => {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
  
		reader.onload = () => {
			resolve(reader.result);
		};
  
		reader.onerror = () => {
			reject(new Error('Failed to read file.'));
		};
	
		reader.readAsArrayBuffer(file);
	});
};

// Filters and sorts the entries for the datastreaming page
DataStreaming.filterAndSortEntries = function(entries)
{
	// We only want to show the datastreaming entries
	// That is entries with the Stream field in it
	const filterMethod = function(entry)
	{
		return entry.entryValue.Stream != null;
	};
	entries = entries.filter(filterMethod);
	entries = Cloud.sortEntriesByDate(entries);
	return entries;
};

DataStreaming.fillDataStreamingTableWithEntries = function(entries)
{
	let tableBody = document.getElementById('DataStreamingTableBody');
	fillTableWithEntries(tableBody, entries, false, false, true, null, null, null);
};

// Fills the table on the datastreaming page with filtered and sorted entries from index file
DataStreaming.fillTableWithFilteredAndSortedEntries = function(indexFile)
{
	let entries = indexFile.GetEntriesAsArray();
	entries = DataStreaming.filterAndSortEntries(entries);
	DataStreaming.fillDataStreamingTableWithEntries(entries);
};

DataStreaming.fillTable = async function()
{
	let indexFile = await getIndexFile(showErrorMessage);
	if (indexFile != null)
	{
		DataStreaming.fillTableWithFilteredAndSortedEntries(indexFile);
	}
};

DataStreaming.uploadToCloud = async () => {

	if (!isLinked())
	{
		goToGroupPage();
		return;
	}

	var encrkey = retrieveEncryptionKey();

	var selectedFiles = document.getElementById('fileInput').files;
	var n = selectedFiles.length;
	if (n < 1)
	{
		showErrorMessage("No files selected!");
	}
	else
	{
		//TODO add support for uploading multiple files
		DataStreaming.totalNrOfFilesBeingSent += 1;
		DataStreaming.nrOfOngoingUploadTasks += 1;
		DataStreaming.updateUploadingMessages(false);

		try
		{
			let filename = selectedFiles[0].name;
			let fileContent = await DataUtil.readFileToBuffer(selectedFiles[0]);
			let fileContentUint8Array = new Uint8Array(fileContent);

			//TODO maybe only allow one upload at a time if needed
			DataStreaming.isUploading = true;
			let res = await DataStreamingCloud.addModelForStreaming(fileContentUint8Array, filename, encrkey);
			DataStreaming.isUploading = false;

			DataStreaming.totalNrOfFilesBeingSent -= 1;
			DataStreaming.nrOfOngoingUploadTasks -= 1;

			if (res.success)
			{
				DataStreaming.updateUploadingMessages(false);
				let indexFile = res.indexFile;
				DataStreaming.fillTableWithFilteredAndSortedEntries(indexFile);
			}
			else
			{
				DataStreaming.updateUploadingMessages(true);
				showErrorMessage("Upload failed!");
			}

		}
		catch (error)
		{
			DataStreaming.totalNrOfFilesBeingSent -= 1;
			DataStreaming.nrOfOngoingUploadTasks -= 1;
			DataStreaming.updateUploadingMessages(true);
			showErrorMessage("Upload failed!");
			console.error('Failure to upload file : ' + error);
		}
	}
};

// Update UI of Datastreaming page
DataStreaming.updateUI = function()
{
	DataStreaming.fillTable();
};

DataStreaming.bodyOnLoad = function()
{
	// Doing this for now since the data streaming page should not be available yet.
	goToGroupPage();

	let linked = isLinked();
	if (!linked)
	{
		// Redirect to manage group page if not connected to group
		goToGroupPage();
	}

	DataStreaming.updateUI();
};
